











































import { StorageSAuthtModel } from "@/store/auth.store";
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      store: (st: any) => st.authStore,
    }),
  },
})
export default class IndexPage extends Vue {
  store!: StorageSAuthtModel;

  get userRoles(): string {
    const names: Array<string> = this.store.authData.roles.map(e => e.name);
    return names.join(",");
  }

  get userBranches(): string {
    return (
      this.store.authData.branchList?.map(e => e.branchName).join(",") ?? ""
    );
  }
}
